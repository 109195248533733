import axios from '@axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async index() {
      const { data } = await axios.get('/user/projects')
      return data
    },
    async get(a, { id }) {
      const { data } = await axios.get(`/user/projects/${id}`)
      return data
    },
    async generateReport(a, { id }) {
      const { data } = await axios.get(`/user/projects/generateReports/${id}`)
      return data
    },
    async deleteReport(a, { id, ProjectId }) {
      const { data } = await axios.delete(`/user/projects/${ProjectId}/projectReports/${id}`)
      return data
    },
    async calc(
      a,
      {
        projectId,
        roomId,
        blindageId,
        payload,
      },
    ) {
      const { data } = await axios.post(
        `/user/projects/${projectId}/rooms/${roomId}/blindages/${blindageId}/calc`,
        payload,
      )
      return data
    },
    async create(a, payload) {
      const { data } = await axios.post('/user/projects', payload)
      return data
    },
    async getupsert(a, id) {
      const { data } = await axios.get(`/user/projects/upsert/${id}`)
      return data
    },
    async upsert(a, project) {
      const { data } = await axios.post('/user/projects/upsert', project)
      return data
    },
  },
}
