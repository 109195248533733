import axios from '@axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async search(a, { addressCode }) {
      const { data } = await axios.get(`/user/addresses/${addressCode}`)
      return data
    },
  },
}
