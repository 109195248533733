import Vue from 'vue'
import VueMask from 'v-mask'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import { localize } from 'vee-validate'
import pt from 'vee-validate/dist/locale/pt_BR.json'
import VueCompositionAPI from '@vue/composition-api'
import '@core/scss/vue/libs/vue-autosuggest.scss'
import '@core/scss/vue/libs/vue-good-table.scss'
import '@core/scss/vue/libs/vue-flatpicker.scss'
import '@core/scss/vue/libs/vue-slider.scss'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Mask
Vue.use(VueMask)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

// Setup vee-validate localization
localize({
  pt: {
    messages: pt.messages,
    names: {
      email: 'Email',
      password: 'Senha',
    },
    fields: {
      password: {
        min: '{_field_} é muito pequeno, você quer ser hackeado?',
      },
    },
  },
})
localize('pt')

Vue.config.productionTip = false
Vue.prototype.$store = store

Vue.prototype.$helpers = {
  getTooltipTarget(name, item) { return `${name}-${item.id}` },

  formatNumber(value) {
    if (value) {
      return value.toFixed(2).replace('.', ',')
    }
    return '0,00'
  },

  currencyMask: createNumberMask({
    prefix: '',
    allowDecimal: true,
    includeThousandsSeparator: false,
    allowNegative: false,
    decimalSymbol: ',',
    thousandsSeparatorSymbol: '.',
  }),
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
