import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'
import { getUserData } from '@/auth/utils'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    getUser() {
      return new Promise((resolve, reject) => {
        const userData = JSON.parse(localStorage.getItem('userData'))
        axios
          .get(`/user/users/${userData.id}`, { headers: { Authorization: `${localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)}` } })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => { reject(error) })
      })
    },
    updateUser({ state }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/user/users/${payload.id}`, payload, { headers: { Authorization: `${localStorage.getItem(useJwt.jwtConfig.storageTokenKeyName)}` } })
          .then(response => {
            state.response = response
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    async welcomeMsgRead({ state }) {
      const { data } = await axios.put(`/user/companies/welcome/${state.company}`, {})
      return data
    },
    async updateUserPassword(a, payload) {
      const user = getUserData()
      const { data } = await axios.put(`/user/users/${user.id}/password`, payload)
      return data
    },
  },
}
