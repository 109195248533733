import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
})

axiosIns.interceptors.response.use(
  response => response,

  error => {
    const status = error.response && error.response.status
    const data = (error.response && error.response.data) || {}
    const { err } = data

    const user = localStorage.getItem('accessToken')

    const isTokenExpired = status === 401 && err && err.name === 'TokenExpiredError'

    if (isTokenExpired && user) {
      Vue.prototype.$store.dispatch('app/logout')
    }

    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
