import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: '/pages/user/list',
      name: 'pages-user',
      component: () => import('@/views/pages/user/List.vue'),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: '/pages/user/create',
      name: 'pages-user-create',
      component: () => import('@/views/pages/user/UserForm.vue'),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: '/pages/area-type-limit',
      name: 'pages-area-type-limit',
      component: () => import('@/views/pages/areaTypeLimit/List.vue'),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: '/pages/barrier',
      name: 'pages-barrier',
      component: () => import('@/views/pages/barrier/List.vue'),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: '/pages/project',
      name: 'pages-project',
      component: () => import('@/views/pages/project/List.vue'),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: '/pages/project/create',
      name: 'pages-project-create',
      component: () => import('@/views/pages/project/ProjectForm.vue'),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: '/pages/project/upsert/:id',
      name: 'pages-project-upsert',
      component: () => import('@/views/pages/project/ProjectUpsertForm.vue'),
      meta: {
      },
    },
    {
      path: '/pages/project/calc/:id',
      name: 'pages-project-calc-blindage',
      component: () => import('@/views/pages/project/CalcBlindage.vue'),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: '/pages/reducing-deci-layer',
      name: 'pages-reducing-deci-layer',
      component: () => import('@/views/pages/reducingDeciLayer/List.vue'),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: '/pages/asset',
      name: 'pages-asset',
      component: () => import('@/views/pages/asset/List.vue'),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: '/pages/asset/create',
      name: 'pages-asset-create',
      component: () => import('@/views/pages/asset/AssetForm.vue'),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: '/pages/calcParam',
      name: 'pages-calc-param',
      component: () => import('@/views/pages/calcParam/List.vue'),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: '/pages/calcParam/upsert/:id',
      name: 'pages-calcparam-upsert',
      component: () => import('@/views/pages/calcParam/CalcParamUpsertForm.vue'),
      meta: {
      },
    },
    {
      path: '/pages/businesPlan',
      name: 'busines-plan',
      component: () => import('@/views/pages/businesPlan/businesPlanForm.vue'),
      meta: {
      },
    },
    {
      path: '/pages/user-setting',
      name: 'pages-user-setting',
      component: () => import('@/views/pages/user-setting/UserSetting.vue'),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: '',
      component: () => import('@/views/AuthWrapper.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
      children: [
        {
          path: '/login',
          name: 'auth-login',
          component: () => import('@/views/Login.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/forget-password/:hash',
          name: 'auth-reset-password',
          component: () => import('@/views/ResetPassword.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
          },
        },
        {
          path: '/forgot-password',
          name: 'auth-forgot-password',
          component: () => import('@/views/ForgotPassword.vue'),
          meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
          },
        },
      ],
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

const afterLoginStorageKey = 'routeAfterLogin'

function tryParseRouteAfterLogin() {
  try {
    const parsed = JSON.parse(localStorage.getItem(afterLoginStorageKey))
    return parsed.name && parsed
  } catch (err) {
    return false
  }
}

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  const routeToGoAfterLogin = tryParseRouteAfterLogin()
  if (isLoggedIn && routeToGoAfterLogin) {
    localStorage.removeItem(afterLoginStorageKey)
    return next(routeToGoAfterLogin)
  }

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    return next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  if (to.meta.requireLogin && !isLoggedIn) {
    const routeAfterLogin = {
      name: to.name,
      params: to.params,
      query: to.query,
    }
    localStorage.setItem(afterLoginStorageKey, JSON.stringify(routeAfterLogin))
    return next({ name: 'auth-login' })
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
