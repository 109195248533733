import axios from '@axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async index() {
      const { data } = await axios.get('/user/calcParams')
      return data
    },
    async getupsert(a, id) {
      const { data } = await axios.get(`/user/calcParams/upsert/${id}`)
      return data
    },
    async upsert(a, calcParam) {
      const { data } = await axios.post('/user/calcParams/upsert', calcParam)
      return data
    },
  },
}
