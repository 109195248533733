import axios from '@axios'
import { getUserData } from '@/auth/utils'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async getUser() {
      const user = getUserData()
      const { data } = await axios.get(`/user/users/${user.id}`)
      return data
    },
    async updateUser(a, payload) {
      const user = getUserData()
      const { data } = await axios.put(`/user/users/${user.id}`, payload)
      return data
    },
  },
}
