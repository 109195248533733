import axios from '@axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async index() {
      const { data } = await axios.get('/admin/users')
      return data
    },
    async create(a, payload) {
      const { data } = await axios.post('/admin/users', payload)
      return data
    },
    async get(a, id) {
      const { data } = await axios.get(`/admin/users/${id}`)
      return data
    },
    delete(a, id) {
      return axios.delete(`/admin/users/${id}`)
    },
  },
}
