export default {
  namespaced: true,
  state: {
    dashboard: null,
    staff: null,
    incomings: null,
    expenses: null,
  },
  getters: {
    dashboard: state => state.dashboard,
    staff: state => state.staff,
    incomings: state => state.incomings,
    expenses: state => state.expenses,
  },
  mutations: {
    UPDATE_DASHBOARD(state, val) { state.dashboard = val },
    UPDATE_STAFF(state, val) { state.staff = val },
    UPDATE_INCOMINGS(state, val) { state.incomings = val },
    UPDATE_EXPENSES(state, val) { state.expenses = val },
  },
  actions: {
  },
}
