import axios from '@axios'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async index() {
      const { data } = await axios.get('/user/barriers')
      return data
    },
  },
}
