import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import businessPlan from './business-plan'
import verticalMenu from './vertical-menu'
import settings from './settings'
import accountSetting from './account-setting'

import areaTypeLimit from './area-type-limit'
import barrier from './barrier'
import project from './project'
import reducingDeciLayer from './reducing-deci-layer'
import asset from './asset'
import address from './address'
import calcParam from './calc-param'

import adminUser from './admin-user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    businessPlan,
    verticalMenu,
    settings,
    accountSetting,
    areaTypeLimit,
    barrier,
    project,
    reducingDeciLayer,
    adminUser,
    asset,
    address,
    calcParam,
  },
  strict: false,
})
